<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center mb-4">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold leading-6 text-gray-900">Deposits</h1>
        <p class="mt-2 text-sm text-gray-700">
          Manage deposits made for the agents.
        </p>
      </div>
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
      </div>
    </div>

    <div class="flex flex-col">
      <chapman-agents v-if="currentTap == 'chapmanagents'" />



    </div>
  </div>
</template>

<script>
import ChapmanAgents from "./ChapmanAgents.vue";

import { mapGetters } from "vuex";
import checkPermissionExist from "../../mixins/checkPermissionExist";

export default {
  components: { ChapmanAgents },
  middleware: "auth",
  layout: "default",

  mixins: [checkPermissionExist],
  computed: mapGetters({
    user: "auth/user",
  }),

  data: () => ({
    currentTap: "chapmanagents",
    branches: null,
  }),

  created() {
    if (!this.checkPermissionExist(this.user.data, "deposit-read")) {
      this.$router.go(-1);
      return false;
    }
  },
};
</script>
